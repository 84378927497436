interface EnvKey {
  [key: string]: string;
}

const DEFAULT_KEY = "6583dbc5ef42af0fe6cb063b";

const ENV_KEY_MAP: EnvKey = {
  REPLICA: "65d5f79f46ea68105aaf78c1",
  PRODUCTION: "64fbfeff9b10f813180d4e70",
  STAGING: "6583dbf424cb1c0f8ec4442f",
  STAGEX: "6583dbf424cb1c0f8ec4442f",
  PE_DEVELOPMENT: "6583dc0e1977de101c1a9df5",
  PLATFORM_DEVELOPMENT: "6583dbff1977de101c1a9df2",
  DEVELOPMENT: "6583dbc5ef42af0fe6cb063b",
  AUTOQA_DEVELOPMENT: "6583dbf424cb1c0f8ec4442f",
};

export const generateLDEnvKey = () => {
  const env: string = process.env.REACT_APP_ENVIRONMENT || "";

  return ENV_KEY_MAP?.[env] || DEFAULT_KEY;
};
